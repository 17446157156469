/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .header {
        .top-menu {
            .header-menu {
                .mobile-menu-toggle {
                    display:block;
                }
                .header-menu-wrapper {
                    display:none;
                }
            }
        }
    }

    body.menu-expanded {
        .header {
            .top-menu {
                position:relative;
                .header-menu {

                    .mobile-menu-toggle {
                        display:block;
                        span {
                            transform:rotate(45deg);
                            top:rem-calc(11);
                            &:nth-of-type(2) {
                                display:none;
                            }
                            &:last-child {
                                transform:rotate(-45deg);
                                top:rem-calc(11);
                            }
                        }
                    }
                    .header-menu-wrapper {
                        display:block;
                        position:absolute;
                        left:rem-calc(-15);
                        right:rem-calc(-15);
                        top:rem-calc(100);
                        background:#fff;
                        .search {
                            padding:rem-calc(20);
                            form {
                                input {
                                    width:100%;
                                }
                            }
                        }
                        .menu {
                            padding:rem-calc(0 20 40 20);
                            > ul {
                                > li {
                                    display:block;
                                    > a {
                                        line-height:rem-calc(40);
                                    }
                                    > .submenu {
                                        display:block;
                                        position:static;
                                        border:none;
                                        padding-left:rem-calc(40);
                                        > li {

                                        }
                                    }
                                    &.has-submenu {
                                        > a {
                                            line-height:rem-calc(30);
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    .slider {
        .slide {
            .row {
                h3 {
                    font-size:rem-calc(28);
                }
            }
        }
    }
    .service-details-wrapper {
        padding:rem-calc(20 0);
        .service-images,
        .service-image {

            img {
                height: rem-calc(300);
            }
        }
    }

    .services {
        padding:rem-calc(20 0);
        .container {
            .block-title {
                h3 {
                    display:block;
                    font-size:rem-calc(24);
                }
            }
        }
    }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .slider {
        .slide {
            height:rem-calc(400);
            img,
            .row {
                height:rem-calc(400);
            }
        }
    }
    .copyrights {
        .powered-text {
            text-align:left;
        }
    }
    .social-icons {
        margin-top:rem-calc(20);
    }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {

}